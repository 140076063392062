/* You can add global styles to this file, and also import other style files */
@import "~ol/ol.css";

.ant-layout{
    background: rgb(248, 248, 248);
}
.ant-page-header{
    background: #fff;
  padding: 18px;
  margin: 3px;
}
.inner-content {
    padding: 18px;
  }
a{
    color: #82358c;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, .ant-menu.ant-menu-dark .ant-menu-item-selected
{
    background-color:#82358c !important
}
.ant-card {
    border-radius: 10px !important;
    
    
}
.ant-card-actions>li>span:hover{
    color: #82358c !important;
}
.ant-card-hoverable:hover{
    border: 1px solid #82358c !important;
    
 box-shadow: 0 1px 2px -2px rgba(130, 53, 140, 0.382), 0 3px 6px 0 rgba(130, 53, 140, 0.25), 0 5px 12px 4px rgba(130, 53, 140, 0.209) !important;
}
.ant-card-actions{
    border-radius: 10px !important;
}
.ant-btn:focus, .ant-btn:hover{
    color: #82358c !important;
    border-color: #82358c !important;
}

.ant-btn-primary{
    background-color: #82358c !important;
    color: white !important;
    border-color: #82358c !important;
}

.ant-btn-primary:hover{
    background-color: #5d2664 !important;
    color: white !important;
}
.ant-btn-primary:focus{
    background-color: #5d2664 !important;
    color: white !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: #82358c !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color: #82358c !important;
    box-shadow: 0 0 0 2px rgba(130, 53, 140,.2) !important;

}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: transparent !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: #e8d7eaf4;
}

.ant-input:hover{
    border-color: #82358c !important;
}

.ant-modal-content{
    border-radius: 10px !important;
}
.ant-modal-header{
    border-radius: 10px !important;
}
.ant-input-number-focused, .ant-input-number:hover{
    border-color: #82358c !important;
}

.ant-input-number-focused{
    box-shadow: 0 0 0 2px rgba(130, 53, 140,.2) !important;
}
.ant-pagination-item-active a, .ant-pagination-item:focus-visible a, .ant-pagination-item:hover a{
    color: #82358c !important;
}
.ant-pagination-item-active{
    border-color: #82358c !important;
}
.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover, .ant-pagination-next:hover button, .ant-pagination-prev:hover button{
    border-color: #82358c !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #82358c !important;
}
.ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus, .ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:focus, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:focus{
    color: #82358c !important;
}
.ant-tabs-tab:hover{
    color: #82358c !important;
}
.ant-tabs-ink-bar{
    background-color: #82358c !important;
}
.ant-page-header-back-button:focus, .ant-page-header-back-button:hover{
    color: #82358c !important;
}
.ant-table-thead>tr>th{
    background-color: #82358c !important;
    color: white !important;
    
}
.ant-table::-webkit-slider-thumb{
    width: 10px;
}

  /* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */


/* Handle */
::-webkit-scrollbar-thumb {
    background: #82358c; 
    border-radius: 10px;
 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #5d2664; 
}
/* .ant-layout-sider-children{
    background-color: #ffffff;
} */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color:#e8d7eaf4 ;
}
.ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open{
    color: #82358c !important;
}
.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item>.ant-badge a:hover{
    color: #82358c !important;
}
.ant-menu-inline .ant-menu-item::after, .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after{
    border-right: 3px solid #5d2664 !important;
}
.ant-layout-sider{
    background: white;
}

.ant-layout-sider-zero-width-trigger{
    background: #82358c !important;

}
.ant-menu-item a:hover{
    color: #82358c !important;
}